// Phone RegExp:
// Simple: /^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/
// Source: https://stackoverflow.com/questions/16699007/regular-expression-to-match-standard-10-digit-phone-number
// Demo: https://regex101.com/r/j48BZs/2
export const phoneRegExp = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

// SSN Validation Regex
// Source: https://stackoverflow.com/questions/34523247/social-security-number-validation-that-accepts-dashes-spaces-or-no-spaces
// Simple SSN Validation: /^(?!(000|666|9))\d{3}(?!00)\d{2}(?!0000)\d{4}$/;
// const ssnRegExp =
//   /^((?!219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4})|((?!219 09 9999|078 05 1120)(?!666|000|9\d{2})\d{3} (?!00)\d{2} (?!0{4})\d{4})|((?!219099999|078051120)(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4})$/;
export const ssnRegExp = /^\d{9}$/;

// Email RegExp
// Source: https://stackoverflow.com/questions/16167983/best-regular-expression-for-email-validation-in-c-sharp
// Exhaustive: https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
// Simple: https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
export const emailRegExp =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;

export const alphaWithSpecialCharsRegExp = /^[a-zA-Z '\-,\.]+$/;
export const alphabetsRegExp = /[a-zA-Z]{1}/;

export const postalCodeRegExp = /^\d{5}(?:[-\s]\d{4})?$/;

export const routingNumberRegExp = /^\d{9}$/;
export const driversLicenseNumberRegExp = /^[a-zA-Z0-9]{4,14}$/;
