import React, { useState } from "react";

const AdHocFiller = (props) => {
  const { authenticityToken, userName, clarityUser } = props;

  const [conversionType, setConversionType] = useState("identity_to_ssn");
  const [inputString, setInputString] = useState("");
  const [resultHeaders, setResultHeaders] = useState([]);
  const [resultRows, setResultRows] = useState([]);

  const conversionOptions = [
    {
      id: "candidate_type_identity_to_ssn",
      value: "identity_to_ssn",
      label: "Identity ID to SSN",
      checked: true
    },
    {
      id: "candidate_type_ssn_to_identity",
      value: "ssn_to_identity",
      label: "SSN to Identity ID",
    },
    {
      id: "candidate_type_bank_account_to_routing_account_pair",
      value: "bank_account_to_routing_account_pair",
      label: "Bank Account ID to Routing Number, Account Number",
    },
    {
      id: "candidate_type_routing_account_pair_to_bank_account",
      value: "routing_account_pair_to_bank_account",
      label: "Routing Number, Account Number to Bank Account ID",
    },
    {
      id: "candidate_type_drivers_license_id_map",
      value: "drivers_license_id_map",
      label: "Drivers License ID to Drivers License State, Number",
    },
    {
      id: "candidate_type_drivers_license_state_number_map",
      value: "drivers_license_state_number_map",
      label: "Drivers License State, Number to Drivers License ID",
    },
    {
      id: "candidate_type_ecan_to_consumer_account_number",
      value: "ecan_to_consumer_account_number",
      label: "Encrypted Tradeline Consumer Account to Account Number",
    },
    {
      id: "candidate_type_consumer_account_number_to_ecan",
      value: "consumer_account_number_to_ecan",
      label: "Tradeline Consumer Account to Encrypted",
    },
  ];

  const announcementMapper = {
    identity_to_ssn: "identity_id",
    ssn_to_identity: "social security number",
    bank_account_to_routing_account_pair: "bank_account_id",
    routing_account_pair_to_bank_account:
      "bank routing number and account number (separated by a comma)",
    drivers_license_id_map: "drivers_license_id",
    drivers_license_state_number_map:
      "drivers_license_state and drivers_license_number (separated by comma)",
    ecan_to_consumer_account_number:
      "encrypted tradeline consumer account number",
    consumer_account_number_to_ecan: "tradeline consumer account number",
  };

  const handleSubmit = () => {
    fetch("/identity_filler_files/ad_hoc", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-csrf-token": authenticityToken,
      },
      body: new URLSearchParams({
        candidate_type: conversionType,
        input_string: inputString,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        let rows = result.split("\n");
        let headers = rows.shift().split(",");
        rows = rows.map((row) => row.split(","));
        setResultHeaders(headers);
        setResultRows(rows);
      });
  }

  return (
    <div>
      <div className="identity-filler-ad-hoc">
        <div className="row">
          <div className="col-sm-6">
            <form className="simple_form candidate" onSubmit={handleSubmit}>
              <input
                type="hidden"
                name="authenticity_token"
                value={authenticityToken}
                autoComplete="off"
              />
              <div className="form-group">
                <label className="control-label">Conversion Type</label>
                <input
                  type="hidden"
                  name="candidate[type]"
                  value=""
                  autoComplete="off"
                />
                {conversionOptions.map(({ id, label, value, checked }) => (
                  <div className="radio" key={id}>
                    <label htmlFor={id}>
                      <input
                        type="radio"
                        value={value}
                        checked={conversionType === value}
                        name="candidate[type]"
                        onChange={(e) => {
                          setConversionType(e.target.value);
                        }}
                        id={id}
                      />
                      <label className="collection_radio_buttons" htmlFor={id}>
                        {label}
                      </label>
                    </label>
                  </div>
                ))}
              </div>
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="announcements">
              <div className="alert alert-info">
                <p className="js-identity-fill-description">
                  Enter one {announcementMapper[conversionType]} per line.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-sm-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <textarea
              className="js-identity-fill-input-string"
              cols="50"
              rows="10"
              onChange={(e) => setInputString(e.target.value)}
              style={{ width: "45%", padding: "10px", borderRadius: "5px" }}
            ></textarea>
            <button
              className="btn btn-warning js-identity-fill-submit"
              onClick={handleSubmit}
              style={{ height: "40px", alignSelf: "center", margin: "10px" }}
            >
              Convert =&gt;
            </button>
            <div
              style={{
                border: "1px solid #767676",
                padding: "10px",
                width: "45%",
                borderRadius: "5px",
                overflow: "auto",
              }}
            >
              <table
                class="js-identity-fill-output-table table table-super-condensed table-striped table-collapsible"
                id="output-table"
              >
                <thead>
                  <tr>
                    {resultHeaders.map((header) => (
                      <th key={header}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {resultRows.map((row) => (
                    <tr>
                      {row.map((cell, id) => (
                        <td key={cell || id}>{cell || "-"}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AdHocFiller.propTypes = {};

export default AdHocFiller;
