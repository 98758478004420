const routingNumberRegExp = /^\d{9}$/;

export function validateRoutingNumber(routingNumber) {
  // All 0's is technically a valid routing number, but it's inactive
  if (!routingNumber) {
    return false;
  }

  // Must be 9 digits
  var match = routingNumberRegExp.test(routingNumber);
  if (!match) {
    return false;
  }

  // The first two digits of the nine digit RTN must be in the ranges 00 through 12, 21 through 32, 61 through 72, or 80.
  // https://en.wikipedia.org/wiki/Routing_transit_number
  const firstTwo = parseInt(routingNumber.substring(0, 2));
  const firstTwoValid =
    (0 <= firstTwo && firstTwo <= 12) ||
    (21 <= firstTwo && firstTwo <= 32) ||
    (61 <= firstTwo && firstTwo <= 72) ||
    firstTwo === 80;
  if (!firstTwoValid) {
    return false;
  }

  // This is the checksum
  // 3(d1 + d4 + d7) + 7(d2 + d5 + d8) + (d3 + d6 + d9) mod 10 = 0
  // http://www.siccolo.com/Articles/SQLScripts/how-to-create-sql-to-calculate-routing-check-digit.html

  // Example of a valid Routing Number: 071025661, 111000038
  // (3 * (0 + 0 + 6) + 7 * (7 + 2 + 6) + (1 + 5 + 1)) % 10 = 0
  // (3 * (1 + 0 + 0) + 7 * (1 + 0 + 3) + (1 + 0 + 8)) % 10 = 0

  const weights = [3, 7, 1];
  var sum = 0;
  for (var i = 0; i < 8; i++) {
    sum += parseInt(routingNumber[i]) * weights[i % 3];
  }

  return (10 - (sum % 10)) % 10 === parseInt(routingNumber[8]);
}
