import React, { useEffect, useState } from "react";
import moment from "moment";
import Select from "react-select";
import { PatternFormat, NumericFormat } from "react-number-format";
import { useFormik, getIn } from "formik";
import UIDatePicker from "./FormikUIDatePicker";
import {
  phoneRegExp,
  ssnRegExp,
  emailRegExp,
  alphaWithSpecialCharsRegExp,
  postalCodeRegExp,
  alphabetsRegExp,
  driversLicenseNumberRegExp,
  routingNumberRegExp
} from "../constants/CommonRegex";
import { validateRoutingNumber } from "../constants/CommonValidations";
import {
  statesOptions,
  generationalSuffixOptions,
  bankAccountTypeOptions,
  relationshipOptions,
  yesOrNo,
  housingStatusOptions,
  payFrequencyOptions,
} from "../constants/CommonDropDownValues";
import { loadLocations, loadControlFiles } from "../utils/GalUtils";
import { debounce } from "lodash";
import * as yup from "yup";

const validationSchema = yup.object({
  location: yup
    .object()
    .shape({
      id: yup.string(),
    })
    .required("Please specify a Location"),
  controlFile: yup
    .object()
    .shape({
      id: yup.string(),
    })
    .required("Please select a Control file"),
  ssn: yup
    .string()
    .required("SSN is required")
    .matches(ssnRegExp, "SSN appears to be invalid or fake or not 9 digits."),
  firstName: yup
    .string()
    .required("First Name is required")
    .matches(
      alphabetsRegExp,
      "The First Name can consist of alphabetical characters only"
    ),
  middleInitial: yup
    .string()
    .max(30, "Please enter a value with valid length")
    .matches(
      alphabetsRegExp,
      "The Middle Initial can consist of alphabetical characters only"
    )
    .notRequired(),
  lastName: yup
    .string()
    .required("Last Name is required")
    .matches(
      alphaWithSpecialCharsRegExp,
      "The Last Name can consist of alphabetical characters only"
    ),
  generationalSuffix: yup.object().shape({ value: yup.string() }).notRequired(),
  addressLineOne: yup.string().required("Street Address 1 is required"),
  addressLineTwo: yup.string().notRequired(),
  city: yup
    .string()
    .required("City is required")
    .matches(
      alphabetsRegExp,
      "The City can consist of alphabetical characters only"
    ),
  state: yup
    .object()
    .shape({
      value: yup.string(),
    })
    .required("State is required"),
  zipCode: yup
    .string()
    .required("Zip Code is required")
    .matches(postalCodeRegExp, "Please enter a valid postal code in USA"),
  housingStatus: yup.object().shape({ value: yup.string() }).notRequired(),
  monthsAtThisAddress: yup
    .string()
    .max(3, "Please enter less than 3 characters")
    .matches(/^\d{1,3}$/, "Please enter a valid number")
    .notRequired(),
  dateOfBirth: yup
    .date()
    .typeError("Please enter a valid date of birth")
    .required("Please enter a date of birth")
    .max(new Date(), "Date of birth cannot be in the future"),
  phoneNumber: yup.object({
    cellPhone: yup
      .string()
      .test(
        "Phone Number Required",
        "You must enter at least one phone number",
        (_val, validationContext) => {
          const {
            createError,
            options: {
              context: { cellPhone, homePhone, workPhone },
            },
          } = validationContext;

          if (cellPhone) {
            return cellPhone && phoneRegExp.test(cellPhone)
              ? true
              : createError({
                  message: "Please enter a valid phone number in USA",
                });
          } else if (workPhone || homePhone) {
            return true;
          }

          return false;
        }
      )
      .nullable(),
    homePhone: yup
      .string()
      .test(
        "Phone Number Required",
        "You must enter at least one phone number",
        (_val, validationContext) => {
          const {
            createError,
            options: {
              context: { cellPhone, homePhone, workPhone },
            },
          } = validationContext;

          if (homePhone) {
            return homePhone && phoneRegExp.test(homePhone)
              ? true
              : createError({
                  message: "Please enter a valid phone number in USA",
                });
          } else if (cellPhone || workPhone) {
            return true;
          }

          return false;
        }
      ),
    workPhone: yup
      .string()
      .test(
        "Phone Number Required",
        "You must enter at least one phone number",
        (_val, validationContext) => {
          const {
            createError,
            options: {
              context: { cellPhone, homePhone, workPhone },
            },
          } = validationContext;

          if (workPhone) {
            return workPhone && phoneRegExp.test(workPhone)
              ? true
              : createError({
                  message: "Please enter a valid phone number in USA",
                });
          } else if (cellPhone || homePhone) {
            return true;
          }

          return false;
        }
      ),
  }),
  driversLicense: yup
    .string()
    .notRequired()
    .min(
      4,
      "The Drivers License Number or Govt ID Number must be more than 4 characters long"
    )
    .max(
      14,
      "The Drivers License Number or Govt ID Number must be less than 14 characters long"
    )
    .matches(
      driversLicenseNumberRegExp,
      "The Drivers License Number can only consist of number and alphabetical characters only"
    ),
  driversLicenseState: yup
    .object()
    .shape({ value: yup.string() })
    .notRequired(),
  emailAddress: yup
    .string()
    .notRequired()
    .matches(emailRegExp, "Please enter a valid email address"),
  netMonthlyIncome: yup.string().required("Net Monthly Income is required"),
  payFrequency: yup.object().shape({ value: yup.string() }).notRequired(),
  paycheckDirectDeposit: yup
    .object()
    .shape({ value: yup.string() })
    .notRequired(),
  nextPayDate: yup.date().typeError("Please enter a valid date").notRequired(),
  bankAccountType: yup.object().shape({ value: yup.string() }).notRequired(),
  routingNumber: yup
    .string()
    .matches(routingNumberRegExp, "Routing number must be 9 digits")
    .test(
      "Phone Number Required",
      "Please enter a valid routing number",
      (routingNumber, _formattedValuevalidationContext) => {
        if (routingNumber) {
          return validateRoutingNumber(routingNumber);
        } else if (!routingNumber) {
          return true;
        }

        return false;
      }
    )
    .notRequired(),
  bankAccountNumber: yup.number().notRequired(),
  employerName: yup.string().notRequired(),
  employerAddress: yup
    .string()
    .max(200, "The Employer Address must be less than 200 characters")
    .notRequired(),
  employerCity: yup
    .string()
    .notRequired("City is required")
    .matches(
      /[a-zA-Z]{1}/,
      "The Employer City can consist of alphabetical characters only"
    ),
  employerState: yup.object().shape({ value: yup.string() }).notRequired(),
  monthsAtCurrentEmployer: yup
    .string()
    .notRequired()
    .max(3, "Please enter less than 3 characters")
    .matches(/^\d{1,3}$/, "Please enter a valid number"),
  referenceFirstName: yup
    .string()
    .matches(
      alphaWithSpecialCharsRegExp,
      "The Reference First Name can consist of alphabetical characters only"
    )
    .notRequired(),
  referenceLastName: yup
    .string()
    .notRequired()
    .matches(
      alphaWithSpecialCharsRegExp,
      "The Reference Last Name can consist of alphabetical characters only"
    ),
  referencePhoneNumber: yup
    .string()
    .matches(phoneRegExp, "The value is not valid US phone number")
    .notRequired(),
  referenceRelationship: yup
    .object()
    .shape({ value: yup.string() })
    .notRequired(),
  passThrough1: yup.string().notRequired(),
  passThrough2: yup.string().notRequired(),
  passThrough3: yup.string().notRequired(),
  passThrough4: yup.string().notRequired(),
  passThrough5: yup.string().notRequired(),
  inquiryCustomerCertification: yup
    .bool()
    .oneOf(
      [true],
      "You must agree and check this statement before submitting a credit report."
    )
    .required(
      "You must agree and check this statement before submitting a credit report."
    ),
});

const initialValues = {
  location: null,
  controlFile: null,
  ssn: "",
  firstName: "",
  middleInitial: "",
  lastName: "",
  generationalSuffix: "",
  addressLineOne: "",
  addressLineTwo: "",
  city: "",
  state: null,
  zipCode: "",
  housingStatus: "",
  monthsAtThisAddress: "",
  dateOfBirth: null,
  cellPhone: "",
  homePhone: "",
  workPhone: "",
  driversLicense: "",
  driversLicenseState: null,
  emailAddress: "",
  netMonthlyIncome: "",
  payFrequency: "",
  paycheckDirectDeposit: "",
  nextPayDate: null,
  bankAccountType: "",
  routingNumber: "",
  bankAccountNumber: "",
  employerName: "",
  employerAddress: "",
  employerCity: "",
  employerState: "",
  monthsAtCurrentEmployer: "",
  referenceFirstName: "",
  referenceLastName: "",
  referencePhoneNumber: "",
  referenceRelationship: "",
  passThrough1: "",
  passThrough2: "",
  passThrough3: "",
  passThrough4: "",
  passThrough5: "",
  inquiryCustomerCertification: false,
};

const formikValuesToAPIPayloadMapper = (
  authenticityToken,
  {
    location,
    controlFile,
    ssn,
    firstName,
    middleInitial,
    lastName,
    generationalSuffix,
    addressLineOne,
    addressLineTwo,
    city,
    state,
    zipCode,
    housingStatus,
    monthsAtThisAddress,
    dateOfBirth,
    cellPhone,
    homePhone,
    workPhone,
    driversLicense,
    driversLicenseState,
    emailAddress,
    netMonthlyIncome,
    payFrequency,
    paycheckDirectDeposit,
    nextPayDate,
    bankAccountType,
    routingNumber,
    bankAccountNumber,
    employerName,
    employerAddress,
    employerCity,
    employerState,
    monthsAtCurrentEmployer,
    referenceFirstName,
    referenceLastName,
    referencePhoneNumber,
    referenceRelationship,
    passThrough1,
    passThrough2,
    passThrough3,
    passThrough4,
    passThrough5,
    inquiryCustomerCertification,
  }
) => {
  let payload = {
    authenticity_token: authenticityToken,
    "cascading_gal[location_id]": location.id,
    "cascading_gal[control_file_id]": controlFile.id,
    "inquiry[social_security_number]": ssn.replace(
      /(\d{3})(\d{2})(\d{4})/,
      "$1-$2-$3"
    ),
    "inquiry[first_name]": firstName,
    "inquiry[middle_initial]": middleInitial,
    "inquiry[last_name]": lastName,
    "inquiry[generational_code]":
      generationalSuffix && generationalSuffix.value,
    "inquiry[street_address_1]": addressLineOne,
    "inquiry[street_address_2]": addressLineTwo,
    "inquiry[city]": city,
    "inquiry[state]": state && state.value,
    "inquiry[zip_code]": zipCode,
    "inquiry[housing_status]": housingStatus && housingStatus.value,
    "inquiry[months_at_address]": monthsAtThisAddress,
    "inquiry[date_of_birth]": moment(dateOfBirth).format("MM/DD/YYYY"),
    "inquiry[cell_phone]": cellPhone.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "$1-$2-$3"
    ),
    "inquiry[home_phone]": homePhone.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "$1-$2-$3"
    ),
    "inquiry[work_phone]": workPhone.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "$1-$2-$3"
    ),
    "inquiry[drivers_license_number]": driversLicense,
    "inquiry[drivers_license_state]":
      driversLicenseState && driversLicenseState.value,
    "inquiry[email_address]": emailAddress,
    "inquiry[net_monthly_income]": netMonthlyIncome,
    "inquiry[pay_frequency]": payFrequency && payFrequency.value,
    "inquiry[paycheck_direct_deposit]":
      paycheckDirectDeposit && paycheckDirectDeposit.value,
    "inquiry[date_of_next_payday]":
      nextPayDate && moment(nextPayDate).format("MM/DD/YYYY"),
    "inquiry[bank_account_type]": bankAccountType && bankAccountType.value,
    "inquiry[bank_routing_number]": routingNumber,
    "inquiry[bank_account_number]": bankAccountNumber,
    "inquiry[employer_name]": employerName,
    "inquiry[employer_address]": employerAddress,
    "inquiry[employer_city]": employerCity,
    "inquiry[employer_state]": employerState && employerState.value,
    "inquiry[months_at_current_employer]": monthsAtCurrentEmployer,
    "inquiry[reference_first_name]": referenceFirstName,
    "inquiry[reference_last_name]": referenceLastName,
    "inquiry[reference_phone]": referencePhoneNumber.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "$1-$2-$3"
    ),
    "inquiry[reference_relationship]":
      referenceRelationship && referenceRelationship.value,
    "inquiry[pass_through_1]": passThrough1,
    "inquiry[pass_through_2]": passThrough2,
    "inquiry[pass_through_3]": passThrough3,
    "inquiry[pass_through_4]": passThrough4,
    "inquiry[pass_through_5]": passThrough5,
    "inquiry[customer_certification]": +inquiryCustomerCertification,
    commit: "Submit",
  };

  let formData = new FormData();

  Object.entries(payload).forEach(([k, v]) => formData.append(k, v));

  return formData;
};

const WebInquiriesContainer = (props) => {
  const { authenticityToken, userName } = props;
  const [controlFileOptions, setControlFileOptions] = useState([]);
  const [trackingNumber, setTrackingNumber] = useState(null);
  const [submissionError, setSubmissionError] = useState(null);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const payload = formikValuesToAPIPayloadMapper(authenticityToken, values);

      fetch("/web_inquiries", {
        method: "POST",
        body: payload,
      })
        .then((res) => res.json())
        .then(({ tracking_number, error, status }) => {
          switch (status) {
            case 200:
              setTrackingNumber(tracking_number);
              break;
            case 400:
              setSubmissionError(error);
              break;
          }
        });
    },
  });

  const confirmAndReset = () => {
    if (confirm("Are you sure you want to clear this form?")) {
      formik.resetForm();
    }
  };

  const {
    values: { location },
    _touched,
    setFieldValue,
  } = formik;

  useEffect(() => {
    trackingNumber &&
      window.location.assign(
        `${window.location.origin}/xml_responses/${trackingNumber}?new_submission=true`
      );
  }, [trackingNumber]);

  useEffect(() => {
    if (location) {
      loadControlFiles(location).then(({ options }) =>
        setControlFileOptions(options)
      );
      setFieldValue("controlFile", null);
    } else {
      setControlFileOptions([]);
      setFieldValue("controlFile", null);
    }
  }, [location]);

  return (
    <>
      {submissionError && (
        <div className="flasher">
          <div className="alert alert-error">
            <button
              className="close"
              data-dismiss="alert"
              type="button"
              onClick={() => setSubmissionError(null)}
            >
              ×
            </button>
            <ul className="list-unstyled margin-bottom-0">
              <li>{submissionError}</li>
            </ul>
          </div>
        </div>
      )}
      <form
        className="new_inquiry fv-form fv-form-bootstrap"
        id="new_inquiry"
        autoComplete="false"
        noValidate="novalidate"
        onSubmit={formik.handleSubmit}
        onBlur={formik.handleBlur}
      >
        <div id="inquiry-form">
          <div className="row">
            <div className="col-sm-12">
              <div className="panel panel-default bg-fa">
                <div className="panel-heading">
                  <h3 className="panel-title">Clarity Account</h3>
                </div>
                <div className="panel-body">
                  <div className="col-md-8 gal">
                    <div className="locations">
                      <div
                        className={`form-group select required cascading_gal_location_id has-${
                          formik.touched.location
                            ? formik.errors.location
                              ? "error"
                              : "success"
                            : ""
                        }`}
                      >
                        <label
                          className="col-sm-3 control-label select required"
                          htmlFor="cascading_gal_location_id"
                        >
                          <abbr title="required">*</abbr> Location
                        </label>
                        <div className="col-sm-9">
                          <Select.Async
                            autoload
                            defaultOptions
                            cacheOptions
                            loadOptions={debounce(loadLocations, 300, {
                              leading: true,
                              trailing: false,
                            })}
                            name="location"
                            id="cascading_gal_location_id"
                            required
                            openOnFocus
                            tabSelectsValue={false}
                            className={`required selectize selectized has-${
                              formik.touched.location
                                ? formik.errors.location
                                  ? "error"
                                  : "success"
                                : ""
                            }`}
                            valueKey="id"
                            labelKey="name"
                            optionRenderer={({ name, id }) => (
                              <div className="option" data-value={id}>
                                <span>
                                  ({id}) {name}
                                </span>
                              </div>
                            )}
                            valueRenderer={({ name, id }) => (
                              <div className="value" data-value={id}>
                                <span>
                                  ({id}) {name}
                                </span>
                              </div>
                            )}
                            spellCheck="false"
                            placeholder="Select a Location"
                            value={formik.values.location}
                            onChange={(payload) => {
                              formik.setFieldValue("location", payload);
                            }}
                          />
                          {formik.touched.location &&
                            formik.errors.location && (
                              <small className="help-block">
                                {formik.errors.location}
                              </small>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="control_files">
                      <div
                        className={`form-group select required cascading_gal_control_file_id has-${
                          formik.touched.controlFile
                            ? formik.errors.controlFile
                              ? "error"
                              : "success"
                            : ""
                        }`}
                      >
                        <label
                          className="col-sm-3 control-label select required"
                          htmlFor="cascading_gal_control_file_id"
                        >
                          <abbr title="required">*</abbr> Control File
                        </label>
                        <div className="col-sm-9">
                          <Select
                            options={controlFileOptions}
                            required
                            openOnFocus
                            tabSelectsValue={false}
                            name="controlFile"
                            id="cascading_gal_control_file_id"
                            className={`required selectize selectized has-${
                              formik.touched.controlFile
                                ? formik.errors.controlFile
                                  ? "error"
                                  : "success"
                                : ""
                            }`}
                            spellCheck="false"
                            placeholder="Select Control File"
                            value={formik.values.controlFile}
                            onChange={(payload) => {
                              formik.setFieldValue("controlFile", payload);
                            }}
                            valueKey="id"
                            labelKey="name"
                            optionRenderer={({ full_name, id }) => (
                              <div className="option" data-value={id}>
                                <span>{full_name}</span>
                              </div>
                            )}
                            valueRenderer={({ full_name, id }) => (
                              <div className="value" data-value={id}>
                                <span>{full_name}</span>
                              </div>
                            )}
                          />
                          {formik.touched.controlFile &&
                            formik.errors.controlFile && (
                              <small className="help-block">
                                {formik.errors.controlFile}
                              </small>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {formik.values.controlFile && (
                    <div className="col-md-4">
                      <div id="inquiry_purpose_type">
                        <dl>
                          <dt>Purpose Type</dt>
                          <dd>
                            {formik.values.controlFile.inquiry_purpose_type}
                          </dd>
                        </dl>
                      </div>
                      <div id="inquiry_tradeline_type">
                        <dl>
                          <dt>Tradeline Type</dt>
                          <dd>
                            {formik.values.controlFile.inquiry_tradeline_type}
                          </dd>
                        </dl>
                      </div>
                      <div id="control_file_products">
                        <dl>
                          <dt>Products</dt>
                          {formik.values.controlFile.products &&
                            formik.values.controlFile.products.map(
                              (product) => <dd key={product}>{product}</dd>
                            )}
                        </dl>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="panel panel-default bg-fa">
                <div className="panel-heading">
                  <h3 className="panel-title">Application Information</h3>
                  <div className="js-field-spinner" />
                </div>
                <div className="panel-body">
                  <div className="col-sm-3">
                    <div
                      className={`form-group string required inquiry_social_security_number1 has-${
                        formik.touched.ssn
                          ? formik.errors.ssn
                            ? "error"
                            : "success"
                          : ""
                      }`}
                    >
                      <label
                        className="control-label string required"
                        htmlFor="inquiry_social_security_number"
                      >
                        <abbr title="required">*</abbr> SSN
                      </label>
                      <PatternFormat
                        className="form-control string required"
                        spellCheck="false"
                        required="required"
                        aria-required="true"
                        placeholder="999-00-9999 fake SSNs ok"
                        format="###-##-####"
                        valueIsNumericString
                        mask="_"
                        id="inquiry_social_security_number"
                        name="ssn"
                        value={formik.values.ssn}
                        onValueChange={(values) => {
                          const { _formattedValue, value } = values;
                          formik.setFieldValue("ssn", value);
                        }}
                      />
                      {formik.touched.ssn && formik.errors.ssn && (
                        <small className="help-block">
                          {formik.errors.ssn}
                        </small>
                      )}
                    </div>
                    <div
                      className={`form-group string required inquiry_first_name has-${
                        formik.touched.firstName
                          ? formik.errors.firstName
                            ? "error"
                            : "success"
                          : ""
                      }`}
                    >
                      <label
                        className="control-label string required"
                        htmlFor="inquiry_first_name"
                      >
                        <abbr title="required">*</abbr> First Name
                      </label>
                      <input
                        className="form-control string required"
                        spellCheck="false"
                        required="required"
                        aria-required="true"
                        type="text"
                        name="firstName"
                        id="inquiry_first_name"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.firstName && formik.errors.firstName && (
                        <small className="help-block">
                          {formik.errors.firstName}
                        </small>
                      )}
                    </div>
                    <div
                      className={`form-group string optional inquiry_middle_initial has-${
                        formik.touched.middleInitial
                          ? formik.errors.middleInitial
                            ? "error"
                            : "success"
                          : ""
                      }`}
                    >
                      <label
                        className="control-label string optional"
                        htmlFor="inquiry_middle_initial"
                      >
                        Middle Initial
                      </label>
                      <input
                        className="form-control string optional"
                        spellCheck="false"
                        type="text"
                        name="middleInitial"
                        id="inquiry_middle_initial"
                        value={formik.values.middleInitial}
                        onChange={formik.handleChange}
                      />
                      <small className="help-block">
                        {formik.touched.middleInitial &&
                          formik.errors.middleInitial && (
                            <small className="help-block">
                              {formik.errors.middleInitial}
                            </small>
                          )}
                      </small>
                    </div>
                    <div
                      className={`form-group string required inquiry_last_name has-${
                        formik.touched.lastName
                          ? formik.errors.lastName
                            ? "error"
                            : "success"
                          : ""
                      }`}
                    >
                      <label
                        className="control-label string required"
                        htmlFor="inquiry_last_name"
                      >
                        <abbr title="required">*</abbr> Last Name
                      </label>
                      <input
                        className="form-control string required"
                        spellCheck="false"
                        required="required"
                        aria-required="true"
                        type="text"
                        name="lastName"
                        id="inquiry_last_name"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                      />
                      <small className="help-block">
                        {formik.touched.lastName && formik.errors.lastName && (
                          <small className="help-block">
                            {formik.errors.lastName}
                          </small>
                        )}
                      </small>
                    </div>
                    <div className="form-group optional inquiry_generational_code">
                      <label
                        className="control-label select optional"
                        htmlFor="inquiry_generational_code"
                      >
                        Generational Suffix
                      </label>
                      <Select
                        name="generationalSuffix"
                        id="inquiry_generational_code"
                        className="optional selectize selectized inquiry_generational_code"
                        spellCheck="false"
                        placeholder="Select a Suffix"
                        value={formik.values.generationalSuffix}
                        onChange={(value) => {
                          formik.setFieldValue("generationalSuffix", value);
                        }}
                        options={generationalSuffixOptions}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div
                      className={`form-group string required inquiry_street_address_1 has-${
                        formik.touched.addressLineOne
                          ? formik.errors.addressLineOne
                            ? "error"
                            : "success"
                          : ""
                      }`}
                    >
                      <label
                        className="control-label string required"
                        htmlFor="inquiry_street_address_1"
                      >
                        <abbr title="required">*</abbr> Address Line 1
                      </label>
                      <input
                        className="form-control string required"
                        spellCheck="false"
                        required="required"
                        aria-required="true"
                        type="text"
                        name="addressLineOne"
                        id="inquiry_street_address_1"
                        value={formik.values.addressLineOne}
                        onChange={formik.handleChange}
                      />
                      <small className="help-block">
                        {formik.touched.addressLineOne &&
                          formik.errors.addressLineOne && (
                            <small className="help-block">
                              {formik.errors.addressLineOne}
                            </small>
                          )}
                      </small>
                    </div>
                    <div className="form-group string optional inquiry_street_address_2">
                      <label
                        className="control-label string optional"
                        htmlFor="inquiry_street_address_2"
                      >
                        Address Line 2
                      </label>
                      <input
                        className="form-control string optional"
                        spellCheck="false"
                        placeholder="Apt 123"
                        type="text"
                        name="addressLineTwo"
                        id="inquiry_street_address_2"
                        value={formik.values.addressLineTwo}
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div
                      className={`form-group string required inquiry_city has-${
                        formik.touched.city
                          ? formik.errors.city
                            ? "error"
                            : "success"
                          : ""
                      }`}
                    >
                      <label
                        className="control-label string required"
                        htmlFor="inquiry_city"
                      >
                        <abbr title="required">*</abbr> City
                      </label>
                      <input
                        className="form-control string required"
                        spellCheck="false"
                        required="required"
                        aria-required="true"
                        type="text"
                        name="city"
                        id="inquiry_city"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                      />
                      <small className="help-block">
                        {formik.touched.city && formik.errors.city && (
                          <small className="help-block">
                            {formik.errors.city}
                          </small>
                        )}
                      </small>
                    </div>
                    <div
                      className={`form-group select required inquiry_state has-${
                        formik.touched.state
                          ? formik.errors.state
                            ? "error"
                            : "success"
                          : ""
                      }`}
                    >
                      <label
                        className="control-label select required"
                        htmlFor="inquiry_state"
                      >
                        <abbr title="required">*</abbr> State
                      </label>
                      <Select
                        className={`required selectize selectized has-${
                          formik.touched.state
                            ? formik.errors.state
                              ? "error"
                              : "success"
                            : ""
                        }`}
                        spellCheck="false"
                        placeholder="Select a State"
                        aria-required="true"
                        name="state"
                        id="inquiry_state"
                        value={formik.values.state}
                        onChange={(value) => {
                          formik.setFieldValue("state", value);
                        }}
                        options={statesOptions}
                      />
                      <small className="help-block">
                        {formik.touched.state && formik.errors.state && (
                          <small className="help-block">
                            {formik.errors.state}
                          </small>
                        )}
                      </small>
                    </div>
                    <div
                      className={`form-group string required inquiry_zip_code has-${
                        formik.touched.zipCode
                          ? formik.errors.zipCode
                            ? "error"
                            : "success"
                          : ""
                      }`}
                    >
                      <label
                        className="control-label string required"
                        htmlFor="inquiry_zip_code"
                      >
                        <abbr title="required">*</abbr> Zip
                      </label>
                      <input
                        className="form-control string required"
                        spellCheck="false"
                        required="required"
                        aria-required="true"
                        type="text"
                        name="zipCode"
                        id="inquiry_zip_code"
                        value={formik.values.zipCode}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.zipCode && formik.errors.zipCode && (
                        <small className="help-block">
                          {formik.errors.zipCode}
                        </small>
                      )}
                    </div>
                    <div className="form-group select optional inquiry_housing_status">
                      <label
                        className="control-label select optional"
                        htmlFor="inquiry_housing_status"
                      >
                        Housing Status
                      </label>
                      <Select
                        placeholder="Rent, Own...?"
                        name="housingStatus"
                        id="inquiry_housing_status"
                        className="optional selectize selectized"
                        spellCheck="false"
                        options={housingStatusOptions}
                        value={formik.values.housingStatus}
                        onChange={(value) => {
                          formik.setFieldValue("housingStatus", value);
                        }}
                      />
                    </div>
                    <div
                      className={`form-group string optional inquiry_months_at_address has-${
                        formik.touched.monthsAtThisAddress
                          ? formik.errors.monthsAtThisAddress
                            ? "error"
                            : "success"
                          : ""
                      }`}
                    >
                      <label
                        className="control-label string optional"
                        htmlFor="inquiry_months_at_address"
                      >
                        Months at this Address
                      </label>
                      <input
                        className="form-control string optional"
                        spellCheck="false"
                        type="text"
                        name="monthsAtThisAddress"
                        id="inquiry_months_at_address"
                        onChange={formik.handleChange}
                        value={formik.values.monthsAtThisAddress}
                      />
                      {formik.touched.monthsAtThisAddress &&
                        formik.errors.monthsAtThisAddress && (
                          <small className="help-block">
                            {formik.errors.monthsAtThisAddress}
                          </small>
                        )}
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div
                      className={`form-group date_picker dob_picker required inquiry_date_of_birth has-${
                        formik.touched.dateOfBirth
                          ? formik.errors.dateOfBirth
                            ? "error"
                            : "success"
                          : ""
                      }`}
                    >
                      <label
                        className="control-label dob_picker required"
                        htmlFor="inquiry_date_of_birth"
                      >
                        <abbr title="required">*</abbr> Date of Birth
                      </label>
                      <UIDatePicker
                        showTodayButton
                        className="date_picker required"
                        disableFuture={true}
                        required
                        label=""
                        aria-required="true"
                        placeholder="MM/DD/YYYY"
                        type="text"
                        name="dateOfBirth"
                        id="inquiry_date_of_birth"
                        date={formik.values.dateOfBirth}
                        onChangeDate={(value) => {
                          formik.setFieldValue("dateOfBirth", value);
                        }}
                        showErrorMessage={false}
                      />
                      {formik.touched.dateOfBirth &&
                        formik.errors.dateOfBirth && (
                          <small className="help-block">
                            {formik.errors.dateOfBirth}
                          </small>
                        )}
                    </div>
                    <div
                      className={`form-group tel optional inquiry_cell_phone has-${
                        formik.touched.cellPhone
                          ? getIn(formik.errors, "phoneNumber.cellPhone")
                            ? "error"
                            : "success"
                          : ""
                      }`}
                    >
                      <label
                        className="control-label tel optional"
                        htmlFor="inquiry_cell_phone"
                      >
                        Cell Phone (need at least one of these phones)
                      </label>
                      <PatternFormat
                        className="form-control string tel optional phoneNumber"
                        spellCheck="false"
                        required="required"
                        aria-required="true"
                        placeholder="(999) 000-9999"
                        format="(###) ###-####"
                        valueIsNumericString
                        mask="_"
                        id="inquiry_cell_phone"
                        name="cellPhone"
                        value={formik.values.cellPhone}
                        onValueChange={(values) => {
                          const { _formattedValue, value } = values;
                          formik.setFieldValue("cellPhone", value);
                        }}
                      />
                      {formik.touched.cellPhone &&
                        getIn(formik.errors, "phoneNumber.cellPhone") && (
                          <small className="help-block">
                            {getIn(formik.errors, "phoneNumber.cellPhone")}
                          </small>
                        )}
                    </div>
                    <div
                      className={`form-group tel optional inquiry_home_phone has-${
                        formik.touched.homePhone
                          ? getIn(formik.errors, "phoneNumber.homePhone")
                            ? "error"
                            : "success"
                          : ""
                      }`}
                    >
                      <label
                        className="control-label tel optional"
                        htmlFor="inquiry_home_phone"
                      >
                        Home Phone
                      </label>
                      <PatternFormat
                        className="form-control string tel optional phoneNumber"
                        spellCheck="false"
                        required="required"
                        aria-required="true"
                        placeholder="(999) 000-9999"
                        format="(###) ###-####"
                        valueIsNumericString
                        mask="_"
                        id="inquiry_home_phone"
                        name="homePhone"
                        value={formik.values.homePhone}
                        onValueChange={(values) => {
                          const { _formattedValue, value } = values;
                          formik.setFieldValue("homePhone", value);
                        }}
                      />
                      {formik.touched.homePhone &&
                        getIn(formik.errors, "phoneNumber.homePhone") && (
                          <small className="help-block">
                            {getIn(formik.errors, "phoneNumber.homePhone")}
                          </small>
                        )}
                    </div>
                    <div
                      className={`form-group tel optional inquiry_work_phone has-${
                        formik.touched.workPhone
                          ? getIn(formik.errors, "phoneNumber.workPhone")
                            ? "error"
                            : "success"
                          : ""
                      }`}
                    >
                      <label
                        className="control-label tel optional"
                        htmlFor="inquiry_work_phone"
                      >
                        Work Phone
                      </label>
                      <PatternFormat
                        className="form-control string tel optional phoneNumber"
                        spellCheck="false"
                        required="required"
                        aria-required="true"
                        placeholder="(999) 000-9999"
                        format="(###) ###-####"
                        valueIsNumericString
                        mask="_"
                        id="inquiry_work_phone"
                        name="workPhone"
                        value={formik.values.workPhone}
                        onValueChange={(values) => {
                          const { _formattedValue, value } = values;
                          formik.setFieldValue("workPhone", value);
                        }}
                      />
                      {formik.touched.workPhone &&
                        getIn(formik.errors, "phoneNumber.workPhone") && (
                          <small className="help-block">
                            {getIn(formik.errors, "phoneNumber.workPhone")}
                          </small>
                        )}
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div
                      className={`form-group string optional inquiry_drivers_license_number has-${
                        formik.touched.driversLicense
                          ? formik.errors.driversLicense
                            ? "error"
                            : "success"
                          : ""
                      }`}
                    >
                      <label
                        className="control-label string optional"
                        htmlFor="inquiry_drivers_license_number"
                      >
                        Drivers License or State ID Number
                      </label>
                      <input
                        className="form-control string optional"
                        spellCheck="false"
                        type="text"
                        name="driversLicense"
                        id="inquiry_drivers_license_number"
                        onChange={formik.handleChange}
                        value={formik.values.driversLicense}
                      />
                      {formik.touched.driversLicense &&
                        formik.errors.driversLicense && (
                          <small className="help-block">
                            {formik.errors.driversLicense}
                          </small>
                        )}
                    </div>
                    <div className="form-group select optional inquiry_drivers_license_state">
                      <label
                        className="control-label select optional"
                        htmlFor="inquiry_drivers_license_state"
                      >
                        Drivers License State
                      </label>
                      <Select
                        className="required selectize selectized"
                        spellCheck="false"
                        placeholder="Select a State"
                        name="driversLicenseState"
                        id="inquiry_drivers_license_state"
                        options={statesOptions}
                        value={formik.values.driversLicenseState}
                        onChange={(value) => {
                          formik.setFieldValue("driversLicenseState", value);
                        }}
                      />
                    </div>
                    <div
                      className={`form-group email optional inquiry_email_address has-${
                        formik.touched.emailAddress
                          ? formik.errors.emailAddress
                            ? "error"
                            : "success"
                          : ""
                      }`}
                    >
                      <label
                        className="control-label email optional"
                        htmlFor="inquiry_email_address"
                      >
                        Email Address
                      </label>
                      <input
                        className="form-control string email optional"
                        spellCheck="false"
                        type="email"
                        name="emailAddress"
                        id="inquiry_email_address"
                        onChange={formik.handleChange}
                        value={formik.values.emailAddress}
                      />
                      {formik.touched.emailAddress &&
                        formik.errors.emailAddress && (
                          <small className="help-block">
                            {formik.errors.emailAddress}
                          </small>
                        )}
                    </div>
                    <div
                      className={`form-group string required inquiry_net_monthly_income has-${
                        formik.touched.netMonthlyIncome
                          ? formik.errors.netMonthlyIncome
                            ? "error"
                            : "success"
                          : ""
                      }`}
                    >
                      <label
                        className="control-label string required"
                        htmlFor="inquiry_net_monthly_income"
                      >
                        <abbr title="required">*</abbr> Net Monthly Income
                        (whole dollars)
                      </label>
                      <NumericFormat
                        className="form-control string required"
                        spellCheck="false"
                        required="required"
                        aria-required="true"
                        valueIsNumericString
                        mask="_"
                        id="inquiry_net_monthly_income"
                        name="netMonthlyIncome"
                        prefix="$"
                        suffix="/-"
                        thousandsGroupStyle="thousand"
                        thousandSeparator=","
                        value={formik.values.netMonthlyIncome}
                        onValueChange={(values) => {
                          const { _formattedValue, value } = values;
                          formik.setFieldValue("netMonthlyIncome", value);
                        }}
                      />
                      {formik.touched.netMonthlyIncome &&
                        formik.errors.netMonthlyIncome && (
                          <small className="help-block">
                            {formik.errors.netMonthlyIncome}
                          </small>
                        )}
                    </div>
                    <div className="form-group select optional inquiry_pay_frequency">
                      <label
                        className="control-label select optional"
                        htmlFor="inquiry_pay_frequency"
                      >
                        Pay Frequency
                      </label>
                      <Select
                        className="required selectize selectized"
                        spellCheck="false"
                        placeholder="Weekly, Monthly...?"
                        name="payFrequency"
                        id="inquiry_pay_frequency"
                        options={payFrequencyOptions}
                        value={formik.values.payFrequency}
                        onChange={(value) => {
                          formik.setFieldValue("payFrequency", value);
                        }}
                      />
                    </div>
                    <div className="form-group select optional inquiry_paycheck_direct_deposit">
                      <label
                        className="control-label select optional"
                        htmlFor="inquiry_paycheck_direct_deposit"
                      >
                        Paycheck Direct Deposit?
                      </label>
                      <Select
                        className="required selectize selectized"
                        spellCheck="false"
                        placeholder="Yes or No?"
                        name="paycheckDirectDeposit"
                        id="inquiry_paycheck_direct_deposit"
                        options={yesOrNo}
                        value={formik.values.paycheckDirectDeposit}
                        onChange={(value) => {
                          formik.setFieldValue("paycheckDirectDeposit", value);
                        }}
                      />
                    </div>
                    <div
                      className={`form-group date_picker optional inquiry_date_of_next_payday has-${
                        formik.touched.nextPayDate
                          ? formik.errors.nextPayDate
                            ? "error"
                            : "success"
                          : ""
                      }`}
                    >
                      <label
                        className="control-label date_picker optional"
                        htmlFor="inquiry_date_of_next_payday"
                      >
                        Next Payday Date
                      </label>
                      <UIDatePicker
                        showTodayButton
                        className="required"
                        placeholder="MM/DD/YYYY"
                        type="text"
                        name="nextPayDate"
                        id="inquiry_date_of_next_payday"
                        date={formik.values.nextPayDate}
                        onChangeDate={(value) => {
                          formik.setFieldValue("nextPayDate", value);
                        }}
                        showErrorMessage={false}
                      />
                      {formik.touched.nextPayDate &&
                        formik.errors.nextPayDate && (
                          <small className="help-block">
                            {formik.errors.nextPayDate}
                          </small>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <div className="panel panel-default bg-fa">
                <div className="panel-heading">
                  <h3 className="panel-title">Bank Information</h3>
                </div>
                <div className="panel-body">
                  <div className="form-group select optional inquiry_bank_account_type">
                    <label
                      className="control-label select optional"
                      htmlFor="inquiry_bank_account_type"
                    >
                      Account Type
                    </label>
                    <Select
                      className="required selectize selectized"
                      spellCheck="false"
                      placeholder="Checking, Savings...?"
                      name="bankAccountType"
                      id="inquiry_bank_account_type"
                      options={bankAccountTypeOptions}
                      value={formik.values.bankAccountType}
                      onChange={(value) => {
                        formik.setFieldValue("bankAccountType", value);
                      }}
                    />
                  </div>
                  <div
                    className={`form-group numeric optional inquiry_bank_routing_number has-${
                      formik.touched.routingNumber
                        ? formik.errors.routingNumber
                          ? "error"
                          : "success"
                        : ""
                    }`}
                  >
                    <label
                      className="control-label numeric optional"
                      htmlFor="inquiry_bank_routing_number"
                    >
                      Routing Number
                    </label>
                    <input
                      className="form-control numeric numeric optional"
                      spellCheck="false"
                      type="text"
                      step="any"
                      name="routingNumber"
                      id="inquiry_bank_routing_number"
                      onChange={formik.handleChange}
                      value={formik.values.routingNumber}
                    />
                    {formik.touched.routingNumber &&
                      formik.errors.routingNumber && (
                        <small className="help-block">
                          {formik.errors.routingNumber}
                        </small>
                      )}
                  </div>
                  <div
                    className={`form-group numeric optional inquiry_bank_account_number has-${
                      formik.touched.bankAccountNumber
                        ? formik.errors.bankAccountNumber
                          ? "error"
                          : "success"
                        : ""
                    }`}
                  >
                    <label
                      className="control-label numeric optional"
                      htmlFor="inquiry_bank_account_number"
                    >
                      Account Number
                    </label>
                    <input
                      className="form-control numeric numeric optional"
                      spellCheck="false"
                      type="number"
                      step="any"
                      name="bankAccountNumber"
                      id="inquiry_bank_account_number"
                      onChange={formik.handleChange}
                      value={formik.values.bankAccountNumber}
                    />
                    {formik.touched.bankAccountNumber &&
                      formik.errors.bankAccountNumber && (
                        <small className="help-block">
                          {formik.errors.bankAccountNumber}
                        </small>
                      )}
                    <small
                      className="help-block"
                      data-fv-validator="digits"
                      data-fv-for="accountNumber"
                      data-fv-result="NOT_VALIDATED"
                      style={{ display: "none" }}
                    >
                      Please enter only digits
                    </small>
                    <small
                      className="help-block"
                      data-fv-validator="callback"
                      data-fv-for="accountNumber"
                      data-fv-result="NOT_VALIDATED"
                      style={{ display: "none" }}
                    >
                      Required when using NEW_CUSTOMER_UNSECURED
                    </small>
                    <small
                      className="help-block"
                      data-fv-validator="numeric"
                      data-fv-for="inquiry[bank_account_number]"
                      data-fv-result="NOT_VALIDATED"
                      style={{ display: "none" }}
                    >
                      Please enter a valid float number
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="panel panel-default bg-fa">
                <div className="panel-heading">
                  <h3 className="panel-title">Employment Information</h3>
                </div>
                <div className="panel-body">
                  <div
                    className={`form-group string optional inquiry_employer_name has-${
                      formik.touched.employerName
                        ? formik.errors.employerName
                          ? "error"
                          : "success"
                        : ""
                    }`}
                  >
                    <label
                      className="control-label string optional"
                      htmlFor="inquiry_employer_name"
                    >
                      Employer Name
                    </label>
                    <input
                      className="form-control string optional"
                      spellCheck="false"
                      type="text"
                      name="employerName"
                      id="inquiry_employer_name"
                      onChange={formik.handleChange}
                      value={formik.values.employerName}
                    />
                    {formik.touched.employerName &&
                      formik.errors.employerName && (
                        <small className="help-block">
                          {formik.errors.employerName}
                        </small>
                      )}
                  </div>
                  <div
                    className={`form-group string optional inquiry_employer_address has-${
                      formik.touched.employerAddress
                        ? formik.errors.employerAddress
                          ? "error"
                          : "success"
                        : ""
                    }`}
                  >
                    <label
                      className="control-label string optional"
                      htmlFor="inquiry_employer_address"
                    >
                      Employer Address
                    </label>
                    <input
                      className="form-control string optional"
                      spellCheck="false"
                      type="text"
                      name="employerAddress"
                      id="inquiry_employer_address"
                      onChange={formik.handleChange}
                      value={formik.values.employerAddress}
                    />
                    {formik.touched.employerAddress &&
                      formik.errors.employerAddress && (
                        <small className="help-block">
                          {formik.errors.employerAddress}
                        </small>
                      )}
                  </div>
                  <div
                    className={`form-group string optional inquiry_employer_city has-${
                      formik.touched.employerCity
                        ? formik.errors.employerCity
                          ? "error"
                          : "success"
                        : ""
                    }`}
                  >
                    <label
                      className="control-label string optional"
                      htmlFor="inquiry_employer_city"
                    >
                      Employer City
                    </label>
                    <input
                      className="form-control string optional"
                      spellCheck="false"
                      pattern="[a-zA-Z]{1}"
                      data-fv-regexp-message="The City can consist of alphabetical characters only"
                      type="text"
                      name="employerCity"
                      id="inquiry_employer_city"
                      onChange={formik.handleChange}
                      value={formik.values.employerCity}
                    />
                    {formik.touched.employerCity &&
                      formik.errors.employerCity && (
                        <small className="help-block">
                          {formik.errors.employerCity}
                        </small>
                      )}
                  </div>
                  <div className="form-group select optional inquiry_employer_state">
                    <label
                      className="control-label select optional"
                      htmlFor="inquiry_employer_state"
                    >
                      Employer State
                    </label>
                    <Select
                      className="required selectize selectized"
                      spellCheck="false"
                      placeholder="Select a State"
                      name="employerState"
                      id="inquiry_employer_state"
                      options={statesOptions}
                      value={formik.values.employerState}
                      onChange={(value) => {
                        formik.setFieldValue("employerState", value);
                      }}
                    />
                  </div>
                  <div
                    className={`form-group string optional inquiry_months_at_current_employer has-${
                      formik.touched.monthsAtCurrentEmployer
                        ? formik.errors.monthsAtCurrentEmployer
                          ? "error"
                          : "success"
                        : ""
                    }`}
                  >
                    <label
                      className="control-label string optional"
                      htmlFor="inquiry_months_at_current_employer"
                    >
                      Months at Current Employer
                    </label>
                    <input
                      className="form-control string optional"
                      spellCheck="false"
                      type="text"
                      name="monthsAtCurrentEmployer"
                      id="inquiry_months_at_current_employer"
                      onChange={formik.handleChange}
                      value={formik.values.monthsAtCurrentEmployer}
                    />
                    {formik.touched.monthsAtCurrentEmployer &&
                      formik.errors.monthsAtCurrentEmployer && (
                        <small className="help-block">
                          {formik.errors.monthsAtCurrentEmployer}
                        </small>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="panel panel-default bg-fa">
                <div className="panel-heading">
                  <h3 className="panel-title">Reference Information</h3>
                </div>
                <div className="panel-body">
                  <div
                    className={`form-group string optional inquiry_reference_first_name has-${
                      formik.touched.referenceFirstName
                        ? formik.errors.referenceFirstName
                          ? "error"
                          : "success"
                        : ""
                    }`}
                  >
                    <label
                      className="control-label string optional"
                      htmlFor="inquiry_reference_first_name"
                    >
                      First Name
                    </label>
                    <input
                      className="form-control string optional"
                      spellCheck="false"
                      type="text"
                      name="referenceFirstName"
                      id="inquiry_reference_first_name"
                      onChange={formik.handleChange}
                      value={formik.values.referenceFirstName}
                    />
                    {formik.touched.referenceFirstName &&
                      formik.errors.referenceFirstName && (
                        <small className="help-block">
                          {formik.errors.referenceFirstName}
                        </small>
                      )}
                  </div>
                  <div
                    className={`form-group string optional inquiry_reference_last_name has-${
                      formik.touched.referenceLastName
                        ? formik.errors.referenceLastName
                          ? "error"
                          : "success"
                        : ""
                    }`}
                  >
                    <label
                      className="control-label string optional"
                      htmlFor="inquiry_reference_last_name"
                    >
                      Last Name
                    </label>
                    <input
                      className="form-control string optional"
                      spellCheck="false"
                      type="text"
                      name="referenceLastName"
                      id="inquiry_reference_last_name"
                      onChange={formik.handleChange}
                      value={formik.values.referenceLastName}
                    />
                    {formik.touched.referenceLastName &&
                      formik.errors.referenceLastName && (
                        <small className="help-block">
                          {formik.errors.referenceLastName}
                        </small>
                      )}
                  </div>
                  <div
                    className={`form-group tel optional inquiry_reference_phone has-${
                      formik.touched.referencePhoneNumber
                        ? formik.errors.referencePhoneNumber
                          ? "error"
                          : "success"
                        : ""
                    }`}
                  >
                    <label
                      className="control-label tel optional"
                      htmlFor="inquiry_reference_phone"
                    >
                      Phone
                    </label>
                    <PatternFormat
                      className="form-control string tel optional phoneNumber"
                      spellCheck="false"
                      required="required"
                      aria-required="true"
                      placeholder="(999) 000-9999"
                      format="(###) ###-####"
                      valueIsNumericString
                      mask="_"
                      id="inquiry_reference_phone"
                      name="referencePhoneNumber"
                      value={formik.values.referencePhoneNumber}
                      onValueChange={(values) => {
                        const { _formattedValue, value } = values;
                        formik.setFieldValue("referencePhoneNumber", value);
                      }}
                    />
                    {formik.touched.referencePhoneNumber &&
                      formik.errors.referencePhoneNumber && (
                        <small className="help-block">
                          {formik.errors.referencePhoneNumber}
                        </small>
                      )}
                  </div>
                  <div className="form-group select optional inquiry_reference_relationship">
                    <label
                      className="control-label select optional"
                      htmlFor="inquiry_reference_relationship"
                    >
                      Relationship
                    </label>
                    <Select
                      className="required selectize selectized"
                      spellCheck="false"
                      placeholder="Select a Relationship Type"
                      name="referenceRelationship"
                      id="inquiry_reference_relationship"
                      options={relationshipOptions}
                      value={formik.values.referenceRelationship}
                      onChange={(value) => {
                        formik.setFieldValue("referenceRelationship", value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="panel panel-default bg-fa">
                <div className="panel-heading">
                  <h3 className="panel-title">Pass Through Fields</h3>
                </div>
                <div className="panel-body">
                  <div className="form-group string optional inquiry_pass_through_1">
                    <label
                      className="control-label string optional"
                      htmlFor="inquiry_pass_through_1"
                    >
                      Pass through 1
                    </label>
                    <input
                      className="form-control string optional"
                      spellCheck="false"
                      type="text"
                      name="passThrough1"
                      id="inquiry_pass_through_1"
                      onChange={formik.handleChange}
                      value={formik.values.passThrough1}
                    />
                  </div>
                  <div className="form-group string optional inquiry_pass_through_2">
                    <label
                      className="control-label string optional"
                      htmlFor="inquiry_pass_through_2"
                    >
                      Pass through 2
                    </label>
                    <input
                      className="form-control string optional"
                      spellCheck="false"
                      type="text"
                      name="passThrough2"
                      id="inquiry_pass_through_2"
                      onChange={formik.handleChange}
                      value={formik.values.passThrough2}
                    />
                  </div>
                  <div className="form-group string optional inquiry_pass_through_3">
                    <label
                      className="control-label string optional"
                      htmlFor="inquiry_pass_through_3"
                    >
                      Pass through 3
                    </label>
                    <input
                      className="form-control string optional"
                      spellCheck="false"
                      type="text"
                      name="passThrough3"
                      id="inquiry_pass_through_3"
                      onChange={formik.handleChange}
                      value={formik.values.passThrough3}
                    />
                  </div>
                  <div className="form-group string optional inquiry_pass_through_4">
                    <label
                      className="control-label string optional"
                      htmlFor="inquiry_pass_through_4"
                    >
                      Pass through 4
                    </label>
                    <input
                      className="form-control string optional"
                      spellCheck="false"
                      type="text"
                      name="passThrough4"
                      id="inquiry_pass_through_4"
                      onChange={formik.handleChange}
                      value={formik.values.passThrough4}
                    />
                  </div>
                  <div className="form-group string optional inquiry_pass_through_5">
                    <label
                      className="control-label string optional"
                      htmlFor="inquiry_pass_through_5"
                    >
                      Pass through 5
                    </label>
                    <input
                      className="form-control string optional"
                      spellCheck="false"
                      type="text"
                      name="passThrough5"
                      id="inquiry_pass_through_5"
                      onChange={formik.handleChange}
                      value={formik.values.passThrough5}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="actions">
            <div className="pull-left">
              <div
                className={`form-group boolean optional inquiry_customer_certification has-${
                  formik.touched.inquiryCustomerCertification
                    ? formik.errors.inquiryCustomerCertification
                      ? "error"
                      : "success"
                    : ""
                }`}
              >
                <div className="checkbox">
                  <label
                    className="boolean optional"
                    htmlFor="inquiry_customer_certification"
                  >
                    <input
                      className="boolean optional"
                      type="checkbox"
                      name="inquiryCustomerCertification"
                      id="inquiry_customer_certification"
                      onChange={formik.handleChange}
                      value={formik.values.inquiryCustomerCertification}
                    />
                    I, {userName}, certify that the application information
                    submitted above is accurate and complete.
                  </label>
                  {formik.touched.inquiryCustomerCertification &&
                    formik.errors.inquiryCustomerCertification && (
                      <small className="help-block">
                        {formik.errors.inquiryCustomerCertification}
                      </small>
                    )}
                </div>
              </div>
            </div>
            <div className="pull-right">
              <input
                type="submit"
                name="commit"
                value="Submit"
                id="create"
                className="btn btn-default btn btn-primary mr-5"
              />
              <button
                name="reset"
                type="button"
                id="reset"
                className="btn btn-default"
                onClick={confirmAndReset}
              >
                Clear Form
              </button>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </form>
    </>
  );
};

WebInquiriesContainer.propTypes = {};

export default WebInquiriesContainer;
